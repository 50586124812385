import React from "react"
import Layout from "../../components/layout-materials"
import { Link } from "gatsby"

export default () => (
  <Layout title="Guitar Teaching Products">

    <h2>Guitar Teaching Products</h2>
    <div className="clear-block">
      <div id="node-29" className="node">

        <div className="content clear-block">
          <p><img src="/images/140-lauren.jpg" className="float-left" width="230" height="173" alt=""/></p>
          <p>Books for teaching Note Reading on the Guitar – this is a list of books created for use in learning basic
            note reading on the guitar.</p>
          <p>Learning the Guitar is not an easy undertaking, whether learning to note read to play a melody or as lead
            guitar or learning chords. The organization of the notes on the guitar does not make much sense to a
            beginner, so learning the notes is a challenge. Fingers of the left hand at first hurt from holding down the
            strings on the fret board and learning the fingering used for the right hand is so important. The Animal
            note method does give the student a way to learn the notes and a way to transfer the knowledge to standard
            music notation, and the student ends up being able to play the melody of songs they enjoy and want to share
            with others.</p>
          <p>“<Link to="/books/Guitar-Note-Reading-Book">Note Reading for the Guitar</Link>” introduces the notes on the
            Grand Staff that are used when playing music on the guitar. It uses the open notes and notes located on the
            top three frets of the guitar. On each page there is a chart of the notes being studied and their location
            on the Grand Staff of Music. Simple little songs provide practice for each additional set of notes learned
            and the relationship of the notes to each other. When these notes are learned the student can play the
            melody line of most songs with no problem. Chords are not used in this book, unless it is a partial chord
            that is printed as part of the music </p>
          <p><img src="/images/149-gabriel.jpg" className="float-right" width="230" height="173" alt=""/>
          </p>
          <p>“<Link to="/flash-cards/Animal-Note-Standard-Note-Flash-Card-Set-Student-Size">Animal Note/standard note
            Flash Card Set</Link>" has a card for each note found on the Grand Staff. The front of each card has the Animal
            Note in its correct location on the staff with the corresponding standard note on the reverse side. A list
            of suggested word clues for each note is included with the card. These should be studied frequently;
            repeating the appropriate word clue for each note while viewing first the Animal Note and then its standard
            note. (Sometimes the child likes to call the standard note the back side of the Animal Note. It’s fun for
            them and sometimes helpful in their learning and understanding.) As the Guitar does not use all of the notes
            of the Grand Staff it is important to study only the notes important to this instrument. </p>
          <p>“<Link to="/books/Beginning-Timing">Timing for Children</Link>” is a study in counting to learn the correct
            proportions between the notes and to develop the ability to hear these differences when they are seen in
            played in a piece of music. It is so important to play these proportions correctly. This is as much a part
            of building a strong foundation in music skills as note reading. Each note played must be identified on the
            Grand Staff and played in its correct timing when playing any piece of music.</p>
          <p>"<Link to="/books/The-Fun-Song-Book">Fun Book–One</Link>” contains simple arrangements of songs familiar to
            most students. It allows applying their newly learn skills of note reading and timing. A couple of new notes
            are introduced, along with sharps and flats. It is always enjoyable to play a song that you recognize and
            those that listen to you playing will recognize. This book can be used in conjunction with the “Timing
            Book.” The last piece of this book will need to be modified as it has harmony notes that are not found on
            the guitar. It would be best to not include the harmony on the guitar at this point in your students music
            training.</p>
          <p>“<Link to="/books/More-Fun-Songs">Fun Book–Two</Link>” adds more harmony in the Bass Clef and new notes. Again,
            for most songs it will be best to only use the melody line of each song. Using this book will give your
            student more time to build their skills before moving on to standard music. You will find it beneficial to
            give your student an opportunity to play more familiar songs while they are learning the basics as it does
            encourage them to practice and learn. </p>
          <p>“<Link to="/books/Christmas-Song-Book">Christmas Song Book</Link>” Like the “Fun Song Books,” this book gives
            the students a chance to play some songs familiar to them and others. However this book is based on a
            Seasonal Theme.</p>
          <p>“<Link to="/books/Theory-Book">Basic Elements of Theory</Link>” is important when studying any instrument. This
            book talks about some of the important and fundamental elements using associative learning, thus providing a
            different way to introduce these important basics of music in a way a child can more easily understand. It
            is not a workbook, but a reference book, to be used when teaching and a place to later review these valuable
            facts.</p>
          <h3>Testimonial</h3>
          <p><i>"My husband so wanted to learn to play melodies on his guitar. He had tried chording and was not at all
            happy with his playing. You talked him into using your 'Guitar Note Reading' book and he is so excited. He
            now plays the songs he so loves and reads notes far better than I have ever been able to. I thank you so
            much for the happiness you have given him."<br/>
            - Carolyn</i></p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
